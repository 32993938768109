<template>
  <div class="add-group-modal">
    <section class="mb-2">
      <label>Upload a image (512px X 512px)</label>
      <b-form-file ref="kiosk-img"
                   v-model="image"
                   size="lg"
                   required
                   accept="image/jpeg, image/png"
                   placeholder="Choose an image or drop it here..."
                   drop-placeholder="Drop file here..."
                   @change="getImageUrl"
      />
      <div v-if="invalidImgRes" class="alert alert-danger p-1 mt-1" role="alert">
        Image must be 512 X 512, current image is {{ imageResolution.width }} X {{ imageResolution.height }}
      </div>

      <div v-if="invalidImgType" class="alert alert-danger p-1 mt-1" role="alert">
        Invalid image, please try again
      </div>
    </section>
    <section v-if="preview_url" class="mb-2 d-flex justify-content-center">
      <img class="img-thumbnail" style="padding: 0; border: none" :src="preview_url" alt="image_preview">
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="clearFiles(); $emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': !image || invalidImgType || invalidImgRes}"
                variant="primary" @click="uploadImage"
      >Upload image</b-button>
    </section>

  </div>
</template>

<script>
import helperService from '@/services/HelperService';
import KioskSiteService from '@/services/KioskSiteService';

export default {
  components: {},
  props: {
    siteId: {
      required: true,
      type: [Number, String],
    },
  },
  data() {
    return {
      image: null,
      preview_url: '',
      imageResolution: {
        height: 0,
        width: 0,
      },
      invalidImgType: false,
    };
  },
  computed: {
    // a computed getter
    invalidImgRes() {
      if (!this.image) {
        return false
      }
      return this.imageResolution.height !== 512 || this.imageResolution.width !== 512
    },
  },
  methods: {
    async uploadImage() {
      try {
        const res = await KioskSiteService.uploadKioskImage(this.siteId, this.image)
        helperService.showNotfySuccess(this.$toast, 'Image uploaded')
        this.$emit('uploaded', res.image)
      } catch (err) {
        const res = err.response
        let errorText = 'Could not upload kiosk image, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }
        helperService.showNotfyErr(this.$toast, err, errorText)
      }
    },
    clearFiles() {
      this.$refs['kiosk-img'].reset()
      this.preview_url = ''
    },
    getImageUrl(e) {
      const file = e.target.files[0];
      this.checkImageSize(file)
      this.preview_url = URL.createObjectURL(file);
    },
    checkImageSize(imageFile) {
      const img = new Image();
      img.onload = () => {
        // set image height and width once image is loaded
        this.imageResolution.height = img.height
        this.imageResolution.width = img.width

        this.invalidImgType = false
      };
      img.onerror = () => {
        this.invalidImgType = true
      };
      img.src = URL.createObjectURL(imageFile);
    },
  },
};
</script>
<style scoped>
</style>

<style lang="scss">
.popover {
  max-width: 500px;
}
</style>
<!--eslint-disable-->
<template>
  <div class="kiosk-site-view-page">
    <b-card  class="" title="Site Details">
      <b-card-text>This page shows details for this kiosk site. You can update the site and its features directly from this page.</b-card-text>
    </b-card>

    <section v-if="!loading">
      <h2 class="pb-1">{{ site.mdm_name }}</h2>
      <b-tabs content-class="my-3">
        <b-tab title="Application Details" :active="isTabActive('application_details')" @click="activeTab = 'application_details'">
          <KioskApplicationDetails v-if="site" :site="site"/>
          <section v-else>Could not get site</section>
        </b-tab>
        <b-tab title="URLs" :active="isTabActive('urls')" @click="activeTab = 'urls'">
          <section v-if="site">

            <b-row>
              <b-col class="col-md-9 col-sm-12">
                <TableData table-name="Url_Headers"
                           :items="urls"
                           :headers="url_headers"
                           :items-selected="urlsSelected"
                           :bulk-input="true"
                           @addentry="addToTable"
                           @delete="showDeleteModal"

                />
              </b-col>

              <b-col class="col-md-3 col-sm-12">
                <TableActions @selected="setSelected" @deleteall="bulkDelete"/>
              </b-col>

            </b-row>
          </section>

          <section v-else>Could not get site</section>
        </b-tab>
        <b-tab title="URL Blacklist" :active="isTabActive('url_blacklist')" @click="activeTab = 'url_blacklist'">
          <section>

            <b-row>

              <b-col class="col-md-9 col-sm-12">
                <TableData table-name="Url_blacklist"
                           :items="url_blacklist"
                           :headers="blacklist_headers"
                           :items-selected="url_blacklistSelected"
                           @addentry="addToTable"
                           @delete="showDeleteModal"
                />
              </b-col>

              <b-col class="col-md-3 col-sm-12">
                <TableActions @selected="setSelected" @deleteall="bulkDelete"/>
              </b-col>

            </b-row>

          </section>
        </b-tab>
        <b-tab title="Resource Whitelist" :active="isTabActive('resource_whitelist')" @click="activeTab = 'resource_whitelist'">
          <section>

            <b-row>

              <b-col class="col-md-9 col-sm-12">
                <TableData table-name="Resource_whitelist"
                           :items="resource_whitelist"
                           :headers="whitelist_headers"
                           :items-selected="resource_whitelistSelected"
                           input-label="You can use the '*' placeholder to represent any characters within the resource e.g. https://*.example.com will include https://server1.example.com. Note: Only the domain should be included e.g. https://server1.example.com/folder is invalid"
                           input-placeholder="Add Url e.g. https://www.example.com"
                           @addentry="addToTable"
                           @delete="showDeleteModal"
                />
              </b-col>

              <b-col class="col-md-3 col-sm-12">
                <TableActions @selected="setSelected" @deleteall="bulkDelete"/>
              </b-col>

            </b-row>

          </section>
        </b-tab>
        <b-tab title="Javascript" :active="isTabActive('javascript')" @click="activeTab = 'javascript'">

          <b-row>
            <b-col class="col-md-12 col-sm-12">

              <label>Update JavaScript here. Remember to save your changes with 'Update'</label>
              <b-input-group>
                <b-form-textarea v-model="newJavascript" rows="15">
                  {{ site.javascript }}
                </b-form-textarea>
              </b-input-group>
              <b-button variant="primary" @click="updateJS" class="mt-1">Update</b-button>

            </b-col>
          </b-row>

        </b-tab>
        <b-tab title="CSS" :active="isTabActive('css')" @click="activeTab = 'css'">

          <b-row>
            <b-col class="col-md-12 col-sm-12">
              <b-form-checkbox class="mb-1" v-model="includeGlobalCss" switch>
                Include Global CSS
              </b-form-checkbox>
              <label>
                Update CSS Rules here. Remember to save your changes with 'Update'. Some global css rules are added automatically. You can see what they are here
                <b-button id="popover-button-default" variant="flat-primary" class="btn-icon p-0">
                  <feather-icon icon="InfoIcon" />
                </b-button>
              </label>

              <b-popover target="popover-button-default" triggers="focus" placement="top">
                <template #title>
                  <span>Global CSS RULES</span>
                </template>

                <pre class="p-2">{{ globalCssRules }}</pre>
              </b-popover>


              <b-input-group>
                <b-form-textarea v-model="newCSS" rows="15">
                  {{ site.css_rules }}
                </b-form-textarea>
              </b-input-group>
              <b-button variant="primary" @click="updateCSS" class="mt-1">Update</b-button>

            </b-col>
          </b-row>

        </b-tab>
        <b-tab title="Shopping" :active="isTabActive('shopping')" @click="activeTab = 'shopping'">
          <KioskShoppingTab v-if="site"
                            :site="site"
                            @update="updateShoppingSettings"
          />
        </b-tab>
      </b-tabs>
    </section>
    <section v-else>
      <b-spinner variant="primary" label="Loading site"></b-spinner>
    </section>

    <b-modal id="modal-update-kiosk-site" title="Delete URL" hide-footer>
      <delete-modal
        v-if="selectedToDelete"
        @close="closeModals()" @delete="deleteValue"
        :subtitle="selectedToDelete.name"
        title="Are you sure you wish to make this change to the site?"></delete-modal>
    </b-modal>

  </div>
</template>
<!--eslint-enable-->

<script>
import KioskSiteService from '@/services/KioskSiteService';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import TableActions from '@/views/kiosk/sections/TableActions.vue';
import TableData from '@/views/kiosk/sections/TableData.vue';
import helperService from '@/services/HelperService';
import KioskShoppingTab from '@/views/kiosk/sections/KioskShoppingTab.vue';
import KioskApplicationDetails from '@/views/kiosk/sections/KioskApplicationDetails.vue';
import KioskGlobalCssService from "@/services/KioskGlobalCssService";

export default {
  name: 'Site',
  components: {
    DeleteModal,
    TableActions,
    TableData,
    KioskShoppingTab,
    KioskApplicationDetails,
  },
  data() {
    return {
      site: null,
      urls: [],
      url_blacklist: [],
      resource_whitelist: [],
      urlsSelected: {},
      url_blacklistSelected: {},
      resource_whitelistSelected: {},
      selectedToDelete: null,
      activeTab: 'application_details',
      newJavascript: '',
      shippingCost: null,
      newCSS: '',
      includeGlobalCss: false,
      loading: true,
      url_headers: [
        {
          key: 'url',
          label: 'URLs',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      blacklist_headers: [
        {
          key: 'url',
          label: 'URL Blacklist',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      whitelist_headers: [
        {
          key: 'url',
          label: 'Resource Whitelist',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      globalCssRules: '',
    };
  },
  mounted() {
    this.getSite(true);
    this.getGlobalCss();
  },
  methods: {
    closeModals() {
      this.$bvModal.hide('modal-update-kiosk-site');
    },
    async getSite(onStart) {
      try {
        const res = await KioskSiteService.getSite(this.$route.params.id)

        this.site = res.data;
        this.newJavascript = res.data.javascript
        this.newCSS = res.data.css_rules
        this.includeGlobalCss = res.data.include_global_css

        this.createTableData()
        if (onStart) {
          this.setCheckboxes()
        }

        if (this.site.shipping_cost) {
          this.site.shipping_cost /= 100
        }
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get site, please refresh and try again')
      } finally {
        this.loading = false;
      }
    },
    setCheckboxes() {
      this.urls.forEach(url => {
        this.urlsSelected[url.url] = false
      })
      this.url_blacklist.forEach(url => {
        this.url_blacklistSelected[url.url] = false
      })
      this.resource_whitelist.forEach(url => {
        this.resource_whitelistSelected[url.url] = false
      })
    },
    showDeleteModal(val, modalName) {
      this.selectedToDelete = val;
      this.$bvModal.show(modalName);
    },
    deleteValue() {
      const index = this.site[this.activeTab].indexOf(this.selectedToDelete)
      this.site[this.activeTab].splice(index, 1)

      this.updateSite()
    },
    isTabActive(tab) {
      return tab === this.activeTab
    },
    addToTable(entry) {
      const listName = this.activeTab

      // Split by newline and trim each entry
      const entries = entry.split('\n').map(val => val.trim()).filter(val => !!val)

      if (this.site[listName]) {
        this.site[listName].push(...entries)
      } else {
        this.site[listName] = entries
      }
      this.updateSite()
    },
    updateJS() {
      this.site.javascript = this.newJavascript
      this.updateSite()
    },
    updateCSS() {
      this.site.css_rules = this.newCSS
      this.site.include_global_css = this.includeGlobalCss
      this.updateSite()
    },
    async getGlobalCss() {
      try {
        const res = await KioskGlobalCssService.getCss()
        this.globalCssRules = res.data.css_rules;
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, 'Could not get global css, please refresh and try again')
      }
    },
    async updateSite() {
      try {
        if (this.shippingCost >= 0) {
          this.site.shipping_cost = Number(this.shippingCost).toFixed(2) * 100
        }
        await KioskSiteService.updateSite(this.site.id, this.site)
        helperService.showNotfySuccess(this.$toast, `Updated  ${this.site.mdm_name} Successfully`)
        this.createTableData()
      } catch (err) {
        const res = err.response
        let errorText = 'Could not update kiosk site, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }
        helperService.showNotfyErr(this.$toast, err, errorText)
      }
    },
    bulkDelete() {
      const listName = this.activeTab;
      const newList = []
      const listSelected = this[`${listName}Selected`]

      this[listName].forEach(val => {
        if (!listSelected[val.url]) {
          newList.push(val.url)
        } else {
          listSelected[val.url] = false
        }
      })

      this.site[listName] = newList
      this.updateSite()
    },
    setSelected(checked) {
      const listName = this.activeTab
      const selected = {}
      const listSelected = `${listName}Selected`

      if (!checked) {
        this[listSelected] = {}
        return
      }

      this[listName].forEach(url => {
        selected[url.url] = checked;
      });
      this[listSelected] = selected;
    },
    createTableData() {
      this.closeModals();
      this.urls = []
      this.url_blacklist = []
      this.resource_whitelist = []

      if (this.site.urls) {
        this.site.urls.forEach((val, index) => {
          this.urls.push({
            id: index,
            url: val,
          })
        })
      }
      if (this.site.url_blacklist) {
        this.site.url_blacklist.forEach((val, index) => {
          this.url_blacklist.push({
            id: index,
            url: val,
          })
        })
      }
      if (this.site.resource_whitelist) {
        this.site.resource_whitelist.forEach((val, index) => {
          this.resource_whitelist.push({
            id: index,
            url: val,
          })
        })
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    updateShoppingSettings(shoppingConfig) {
      this.site.shop_enabled = shoppingConfig.enabled
      this.site.checkout_message = shoppingConfig.checkoutMessage
      this.shippingCost = shoppingConfig.shippingCost
      this.updateSite()
    },
  },
};
</script>

<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(submitForm)">

      <b-row>
        <b-col class="col-md-7">
          <b-card>
            <label class="mr-sm-2" for="shop-enabled">Enable Shop</label>
            <b-form-select id="shop-enabled" v-model="shoppingConfig.enabled" class="mb-1">
              <b-form-select-option :value="true">Enable</b-form-select-option>
              <b-form-select-option :value="false">Disable</b-form-select-option>
            </b-form-select>
            <section v-if="shoppingConfig.enabled" class="mb-2">
              <div>
                <label>Set shipping cost for this shop </label>
                <validation-provider
                  v-slot="validationContext"
                  name="Amount"
                  :rules="{ required:true, min_value:0}"
                  :custom-messages="{required:'Please enter a valid amount', min_value: 'Amount must be a positive amount'}"
                >
                  <b-form-input
                    v-model="shoppingConfig.shippingCost"
                    :state="getValidationState(validationContext)"
                    type="number" step="0.01" min="0.00" max="1000.00" class="mb-1"
                    :number="true"
                  />

                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
                <h5 v-if="shoppingConfig.shippingCost*100 !== 0">Shipping cost: {{ shoppingConfig.shippingCost*100 | intToCurrency }}</h5>
                <h5 v-else>Shipping Cost: £0</h5>

                <label>Set a checkout message for this shop</label>
                <b-form-textarea
                  v-model="shoppingConfig.checkoutMessage"
                  class="pt-2"
                  row="3"
                />
              </div>
            </section>
            <b-button variant="primary" class="mt-1" type="submit">Update</b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
export default {
  name: 'KioskShoppingTab',
  props: {
    site: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      shoppingConfig: {
        enabled: this.site.shop_enabled,
        shippingCost: this.site.shipping_cost,
        checkoutMessage: this.site.checkout_message,
      },
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    submitForm() {
      this.$emit('update', this.shoppingConfig)
    },
  },
}
</script>

<style scoped>

</style>

<template>

  <b-card>
    <p class="font-weight-bolder">Actions</p>
    <b-button variant="primary" class="mb-1 full-width" @click="$emit('selected', true)">Select all</b-button>
    <b-button variant="primary" class="mb-1 full-width" @click="$emit('selected', false)">Unselect all</b-button>
    <b-button variant="danger" class="mb-1 full-width" @click="$emit('deleteall')">Delete selected</b-button>
  </b-card>
</template>

<script>
export default {
  name: 'TableActions',
}
</script>

<style scoped>

</style>

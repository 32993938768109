<template>
  <div>
    <label v-if="inputLabel">{{ inputLabel }}</label>
    <b-input-group v-if="!bulkInput" class="mb-1">
      <b-form-input
        v-model="newEntry" class="d-inline-block" :placeholder="inputPlaceholder ? inputPlaceholder : 'Add URL'" @keyup.enter="addEntry"
      />
      <b-input-group-append>
        <b-button variant="primary" :disabled="newEntry === ''" @click="addEntry">Add</b-button>
      </b-input-group-append>
    </b-input-group>

    <div v-else class="mb-1 text-right">
      <b-textarea v-model="newEntry" placeholder="URLs can be submitted in bulk; one URL per line" />
      <b-button variant="primary" class="mt-1" @click="addEntry">Submit</b-button>
    </div>

    <b-table
      hover
      :items="items"
      :fields="headers"
    >
      <template #cell(actions)="data">
        <b-dropdown class="d-inline m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <i class="fas fa-ellipsis-v" />
          </template>
          <b-dropdown-item-button class="full-nested-btn" @click="$emit('delete', data.item.url, 'modal-update-kiosk-site')">
            Delete
          </b-dropdown-item-button>
        </b-dropdown>
        <b-form-checkbox v-model="itemsSelected[data.item.url]" class="d-inline ml-0" />
      </template>
    </b-table>
  </div>

</template>

<script>
import helperService from '@/services/HelperService';

export default {
  name: 'TableData',
  props: {
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    itemsSelected: {
      type: Object,
      required: true,
    },
    inputLabel: {
      type: String,
      required: false,
      default: '',
    },
    inputPlaceholder: {
      type: String,
      required: false,
      default: '',
    },
    bulkInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    tableName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newEntry: '',
    }
  },
  methods: {
    addEntry() {
      // If table is resource whitelist
      if (this.tableName === 'Resource_whitelist') {
        // Check if input is a valid hostname
        if (!this.isResourceWhitelistValid(this.newEntry)) {
          return
        }
      }
      this.$emit('addentry', this.newEntry.trim())
      this.newEntry = ''
    },
    isResourceWhitelistValid(entry) {
      try {
        // If all sites are allowed then return true
        if (entry === '*') {
          return true
        }

        // parse input into a url
        const url = new URL(entry)

        // Check if any additional information other than the hostname is present
        if (url.pathname !== '/' || url.query !== undefined || url.hash !== '') {
          if (url.query === undefined) {
            url.query = ''
          }
          // Construct error message
          const errorMessage = `Invalid resource whitelist: ${url.pathname}${url.query}${url.hash} is not allowed`
          helperService.showNotfyErr(this.$toast, '', errorMessage)
          return false
        }
        // Is valid Url Hostname
        return true
      } catch (err) {
        const errorMessage = `${entry} is not a valid URL`
        helperService.showNotfyErr(this.$toast, err, errorMessage)
        return false
      }
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(updateDetails)">
        <b-row>
          <b-col class="col-12">

            <div class="row justify-content-between">

              <!-- Left card -->
              <div class="col-md-6 col-lg-8">
                <b-card>
                  <section>
                    <div class="mb-1">
                      <label>Dashboard name *</label>
                      <validation-provider
                        v-slot="validationContext"
                        name="Name"
                        :rules="{ required:true }"
                      >
                        <b-form-input
                          v-model="kioskApplicationDetails.name"
                          :state="getValidationState(validationContext)"
                        />

                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>

                    <div class="mb-1">
                      <label>Initial load url *</label>
                      <validation-provider
                        v-slot="validationContext"
                        name="Inital load url"
                        :rules="{ required: true, url }"
                      >
                        <b-form-input
                          v-model="kioskApplicationDetails.initial_load_url"
                          :state="getValidationState(validationContext)"
                        />

                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>

                    <div class="mb-1">
                      <label>Tags</label>
                      <v-select
                        v-model="kioskApplicationDetails.tags"
                        multiple
                        :components="{Deselect}"
                        dir="ltr"
                        label="name"
                        :reduce="(option) => option.id"
                        :options="tags"
                      />
                    </div>

                    <div class="mb-1">
                      <label>User Agent</label>
                      <b-form-input
                        v-model="kioskApplicationDetails.user_agent"
                        placeholder="Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/89.0.4389.128 Safari/537.3"
                      />
                    </div>

                  </section>
                  <b-button variant="primary" class="mt-1" type="submit">Update</b-button>
                </b-card>
              </div>

              <!-- Right card -->
              <div class="col-md-6 col-lg-4">
                <b-card>
                  <div class="mb-1">
                    <label>Application Image</label>
                    <b-button class="d-block" variant="primary" type="button" @click="$bvModal.show('modal-upload-image')">Upload Image</b-button>
                    <div v-if="kioskApplicationDetails.image" class="mt-1">
                      <label>Current Image</label>
                      <img class="img-fluid rounded d-block" style="max-height: 500px; background: white; width: 100%" :src="kioskApplicationDetails.image" alt="application_image">
                    </div>
                  </div>
                </b-card>
              </div>

            </div>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-modal id="modal-upload-image" title="Upload Image" hide-footer>
      <KioskUploadImage :site-id="site.id" @uploaded="updateImage" @close="$bvModal.hide('modal-upload-image')" />
    </b-modal>
  </div>
</template>

<script>
import { url } from '@core/utils/validations/validations';
import helperService from '@/services/HelperService';
import kioskSiteService from '@/services/KioskSiteService';
import KioskUploadImage from '@/views/kiosk/sections/KioskUploadImage.vue';
import kioskTagService from '@/services/KioskTagService';
import vSelect from 'vue-select';

export default {
  name: 'KioskApplicationDetails',
  components: {
    KioskUploadImage,
    vSelect,
  },
  props: {
    site: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      kioskApplicationDetails: {
        name: this.site.name,
        initial_load_url: this.site.initial_load_url,
        image: this.site.image,
        user_agent: this.site.user_agent,
        tags: this.site.tags.map(obj => obj.id),
      },
      tags: [],
      Deselect: {
        render: createElement => createElement('span', 'x'),
      },
    }
  },
  computed: {
    url() {
      return url
    },
  },
  mounted() {
    this.getTags();
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async updateDetails() {
      try {
        await kioskSiteService.updateApplicationDetails(this.site.id, this.kioskApplicationDetails)

        helperService.showNotfySuccess(this.$toast, `Updated  ${this.site.mdm_name} Successfully`)
      } catch (err) {
        const res = err.response
        let errorText = 'Could not update kiosk site application details, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }
        helperService.showNotfyErr(this.$toast, err, errorText)
      }
    },
    updateImage(filepath) {
      this.kioskApplicationDetails.image = filepath
      this.$bvModal.hide('modal-upload-image')
    },
    async getTags() {
      try {
        const res = await kioskTagService.listTags({ page_size: 100 })
        this.tags = res.data.data
      } catch (err) {
        const res = err.response
        let errorText = 'Could not get kiosk tags, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }
        helperService.showNotfyErr(this.$toast, err, errorText)
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
